import * as React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import MainCard from "../components/main-card"
import { MarkdownRemark } from "../contracts/markdown-remark"

interface IndexProps {
  allMarkdownRemark: {
    nodes: MarkdownRemark[]
  }
  ogloszenia: {
    nodes: MarkdownRemark[]
  }
  sakramenty: {
    nodes: MarkdownRemark[]
  }
  grupyParafialne: {
    nodes: MarkdownRemark[]
  }
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const Index: React.FC<PageProps<IndexProps>> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || "Tytuł"
  const posts = data.allMarkdownRemark.nodes
  const postsOrder = [
    "ogloszenia",
    "intencje",
    "rekolekcje",
    "triduum",
    "transmisja",
    "czuwanie",
    "formacja",
    "wizyta-duszpasterska",
    "sakramenty-main",
    "msze-sw",
    "grupy-parafialne-main",
  ]
  const ogloszenia = data.ogloszenia.nodes[0]
  if (
    ogloszenia &&
    !posts.find(post => post.frontmatter.layout === "ogloszenia")
  ) {
    posts.push(ogloszenia)
  }
  const sakramentyMainId = posts.findIndex(
    p => p.frontmatter.layout === "sakramenty-main"
  )
  if (sakramentyMainId >= 0) {
    const sakramenty = data.sakramenty?.nodes?.[0]
    posts[sakramentyMainId].frontmatter.date =
      sakramenty?.frontmatter?.date ?? posts[sakramentyMainId].frontmatter.date
  }
  const grupyParafialneMainId = posts.findIndex(
    p => p.frontmatter.layout === "grupy-parafialne-main"
  )
  if (grupyParafialneMainId >= 0) {
    const grupyParafialne = data.grupyParafialne?.nodes?.[0]
    posts[grupyParafialneMainId].frontmatter.date =
      grupyParafialne?.frontmatter?.date ??
      posts[grupyParafialneMainId].frontmatter.date
  }
  posts.sort(
    (a, b) =>
      postsOrder.indexOf(a.frontmatter.layout) -
      postsOrder.indexOf(b.frontmatter.layout)
  )
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Strona główna" />
      <div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto lg:max-w-none">
            <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-6 gap-y-10">
              {posts.map(post => {
                return (
                  <MainCard
                    key={post.fields.slug}
                    thumbnail={post.frontmatter.thumbnail}
                    title={post.frontmatter.title || post.fields.slug}
                    date={post.frontmatter.date}
                    slug={post.fields.slug}
                    description={post.frontmatter.description}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: { frontmatter: { visible_card: { eq: true } } }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM YYYY", locale: "pl")
          title
          thumbnail
          layout
          description
        }
      }
    }
    ogloszenia: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { layout: { eq: "ogloszenia" } } }
      limit: 1
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM YYYY", locale: "pl")
          title
          thumbnail
          layout
          description
        }
      }
    }
    sakramenty: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { layout: { eq: "sakramenty" } } }
      limit: 1
    ) {
      nodes {
        frontmatter {
          date(formatString: "DD MMMM YYYY", locale: "pl")
        }
      }
    }
    grupyParafialne: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { layout: { eq: "grupy-parafialne" } } }
      limit: 1
    ) {
      nodes {
        frontmatter {
          date(formatString: "DD MMMM YYYY", locale: "pl")
        }
      }
    }
  }
`
